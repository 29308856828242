import React, { useState, useEffect } from "react";
import "./App.css";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { createDbcKey, updateDbcKey, deleteDbcKey } from "./graphql/mutations";
import { dbckeyByKeyNumber, listDbcKeys } from "./graphql/queries";
import Amplify, { API, graphqlOperation, Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import Tabs from "./components/Tabs";
import toast, { Toaster } from 'react-hot-toast';


Amplify.configure(awsExports);

function App() {
 
  const [userName, setUserName] = useState(null);  // non-null means checkUser() has succeeded at some point during this session.
  const [userGrps, setUserGrps] = useState(null);
  const [userTabs, setUserTabs] = useState(0);     // bitset: 1 pass, 2 key, 4 list.

  async function checkUser() {
    //console.log('prev user name:   ', userName);
    //console.log('prev user groups: ', userGrps ? userGrps.join(", ") : "none");
    //console.log('prev user tabs:   ', userTabs);

    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      //console.log('user:', user);
    }
    catch (e) {
      alert("Error getting currentAuthenticatedUser: " + JSON.stringify(e));

      /* don't change state to virgin.  if this is first call, state is still virgin anyway.
         and if this is NOT first call, it's better to leave him with the same tabs visible.
      setUserName(null);
      setUserGrps(null);
      setUserTabs(0);
      */

      return;
    }

    const user_email = user.attributes.email;
    //console.log('curr user name:   ', user_email);

    // the array of groups that the user belongs to, else null...
    const user_groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    //console.log('curr user groups: ', user_groups ? user_groups.join(", ") : "none");

    let user_tabs = 0;
    if (user_groups) {
      if (user_groups.find((g) => g === "security"))
        user_tabs |= 3;
      if (user_groups.find((g) => g === "admin"))
        user_tabs |= 7;
    }
    //console.log('curr user tabs:   ', user_tabs);

    // if we already have userName then this is NOT the first successful call initializing us, and 
    // thus a change in tabs indicates Access/ID tokens were refreshed since last time we checked, and 
    // they indicate a change in the user's group membership... and thus what tabs he should see.
    if (userName && userTabs !== user_tabs)
      toast(userTabs === 0 ? "Your userid was approved :-)" : "Your userid's approval status changed.");

    setUserName(user_email);
    setUserGrps(user_groups);
    setUserTabs(user_tabs);
  }


  const [allDbcKeys, setAllDbcKeys] = useState(null);
  const [sortMayMakeSense, setSortMayMakeSense] = useState(false);

  const loadAllDbcKeys = async () => {
    let gql_result;
    try {
      gql_result = await API.graphql(graphqlOperation(listDbcKeys, { limit: 999 }));  // default max rows is 100; incr big enough to get all.
      //console.log("gql_result = " + JSON.stringify(gql_result));
    }
    catch (e) {
      alert("Error getting listDbcKeys: " + JSON.stringify(e));

      setAllDbcKeys(null);

      return;
    }
    finally {
      // Access/ID tokens may have expired and been refreshed... recheck user's groups and modify visible tabs accordingly.   
      checkUser();
    }

    sortAllDbcKeys(gql_result.data.listDbcKeys.items);
  }

  const sortAllDbcKeys = async (arr) => {
    let new_arr = [...arr];
    new_arr.sort(cmpDbcKeys);
    setAllDbcKeys(new_arr);
    setSortMayMakeSense(false);
  }

  function ifStringIsAllDigitsThenReturnNumberElseReturnGivenString(s) {
    return /^\d+$/.test(s) ? parseInt(s) : s;
  }

  function ifBothStringsAreAllDigitsThenCompareAsNumbersElseCompareAsStrings(a, b) {
    let aa = ifStringIsAllDigitsThenReturnNumberElseReturnGivenString(a);
    let bb = ifStringIsAllDigitsThenReturnNumberElseReturnGivenString(b);

    if (typeof aa !== 'number')
      bb = b;
    else if (typeof bb !== 'number')
      aa = a;

    if (aa < bb)
      return -1;

    if (aa > bb)
      return 1;

    return 0;
  }

  function cmpDbcKeys(a, b) {
    let rc;

    rc = ifBothStringsAreAllDigitsThenCompareAsNumbersElseCompareAsStrings(a.owner_name, b.owner_name);
    if (rc !== 0)
      return rc;

    if (a.key_type < b.key_type)
      return -1;
    if (a.key_type > b.key_type)
      return 1;

    rc = ifBothStringsAreAllDigitsThenCompareAsNumbersElseCompareAsStrings(a.key_number, b.key_number);
    if (rc !== 0)
      return rc;

    if (a.key_is_missing && !b.key_is_missing)
      return -1;
    if (!a.key_is_missing && b.key_is_missing)
      return 1;

    return 0;
  }


  // one-time init to be done when the app is mounted, with no cleanup needed when it's unmounted.
  useEffect(() => {
    checkUser();
    loadAllDbcKeys();

  // React Hook useEffect has a missing dependency: 'loadAllDbcKeys'. Either include it or remove the dependency array  react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  // empty array of "only call effect if objects change value" so effect is only called once on mount (and once on dismount).


  /*
  // cognito Access and ID tokens are configured to expire every 7 minutes.  each time the tokens are refreshed, there's the chance 
  // we'll find that the user's group membership has changed, and that controls what tabs the user should see... so we check often.
  // if user caused an api call since expiration, that refreshed the tokens, else our checking will be the network call that does it.
  // but if the tokens are still good when we check them, that's a cheap local operation, so no harm caused by frequent polling.
  // well actually, there is SOME harm... it means user always needs network coverage for the entire time he's in the app, else err.
  useEffect(() => {
    const id = setInterval(checkUser, 60000);

    return () => {
      clearInterval(id)
    }

  }, []);  // empty array of "only call effect if objects change value" so effect is only called once on mount (and once on dismount).
  */


  const [search_keynum_k, setSearchKeynumK] = useState("");
  const [search_result_k, setSearchResultK] = useState([]);  // any number of result strings, displayed in order.

  const changeSearchKeynumK = (e) => {
    setSearchKeynumK(e.target.value.replace(/ +/, "").replace(/^0+/, ""));
    setSearchResultK([]);
  };

  const clearSearchResultK = (e) => {
    setSearchResultK([]);
  };

  const submitGetDbckeyByKeyNumberK = async (e) => {
    e.preventDefault();
    if (search_keynum_k === "") {
      setSearchResultK([]);
      alert("SEARCH KEY# input field cannot be empty.");
      return;
    }

    getDbckeyByKeyNumberFilteredByType(search_keynum_k, "K", setSearchResultK);
    
    setSearchKeynumK("");
    document.getElementById("search_keynum_k").value = "";
  };
  

  const [search_keynum_p, setSearchKeynumP] = useState("");
  const [search_result_p, setSearchResultP] = useState([]);  // any number of result strings, displayed in order.

  const changeSearchKeynumP = (e) => {
    setSearchKeynumP(e.target.value.replace(/ +/, "").replace(/^0+/, ""));
    setSearchResultP([]);
  };

  const clearSearchResultP = (e) => {
    setSearchResultP([]);
  };

  const submitGetDbckeyByKeyNumberP = async (e) => {
    e.preventDefault();
    if (search_keynum_p === "") {
      setSearchResultP([]);
      alert("SEARCH PASS# input field cannot be empty.");
      return;
    }

    getDbckeyByKeyNumberFilteredByType(search_keynum_p, "P", setSearchResultP);
    
    setSearchKeynumP("");
    document.getElementById("search_keynum_p").value = "";
  };
  

  const getDbckeyByKeyNumberFilteredByType = async (keynum, keytyp, setResultFunc) => {
    const typName = keytyp === "K" ? "Key" : keytyp === "P" ? "Pass" : ("?(" + keytyp + ")");

    let gql_result;
    try {
      gql_result = await API.graphql(graphqlOperation(dbckeyByKeyNumber, { key_number: keynum }));
      //console.log("gql_result = " + JSON.stringify(gql_result));
    }
    catch (e) {
      alert("Error getting dbckeyByKeyNumber(" + keynum + "): " + JSON.stringify(e));

      setResultFunc(["A system error has occurred.", "Unable to lookup " + keytyp + " '" + keynum + "'."]);

      return;
    }
    finally {
      // Access/ID tokens may have expired and been refreshed... recheck user's groups and modify visible tabs accordingly.   
      checkUser();
    }

    const result_dbckeys = [...gql_result.data.dbckeyByKeyNumber.items].filter(({ key_type: _kt }) => _kt === keytyp);

    if (result_dbckeys.length === 0)
      setResultFunc(["No such " + typName + " '" + keynum + "' !!!"]);
    else if (result_dbckeys.length === 1) {
      const dbckey = result_dbckeys[0]
      setResultFunc(["Unit '" + dbckey.owner_name + "' owns " + typName + " '" + keynum + "'" + 
                    (dbckey.key_is_missing ? ", but it was LOST" : "") + "."]);
    }
    else {
      let result_array = [];

      result_array.push("Something UNEXPECTED...");
      result_array.push("There were " + result_dbckeys.length + " matches for " + typName + " '" + keynum + "':");
      for (let dbckey of result_dbckeys)
        result_array.push("   Unit '" + dbckey.owner_name + "'" +  (dbckey.key_is_missing ? ", but it was LOST" : "") + ".");

      setResultFunc(result_array);
    }
  }


  const [owner_name,     setOwnerName]    = useState("");
  const [key_type,       setKeyType]      = useState("P");  // always set (K or P), no unselected/empty state.
  const [key_number,     setKeyNumber]    = useState("");
  const [key_is_missing, setKeyIsMissing] = useState(false);
  
  const submitAddDbcKey = async (e) => {
    e.preventDefault();
    if (owner_name === "") return alert("ADD UNIT# input field cannot be empty.");
    if (key_number === "") return alert("ADD ITEM# input field cannot be empty.");

    const dbckey = { owner_name, key_number, key_is_missing, key_type};

    let gql_result;
    try {
      gql_result = await API.graphql(graphqlOperation(createDbcKey, { input: dbckey }));  // result will include assigned id.
      //console.log("gql_result = " + JSON.stringify(gql_result));
    }
    catch (e) {
      alert("Error doing createDbcKey(" + 
            owner_name + " owns " + (key_is_missing ? "missing " : "") + key_type + ":" + key_number + "): " + 
            JSON.stringify(e));

      return;
    }
    finally {
      // Access/ID tokens may have expired and been refreshed... recheck user's groups and modify visible tabs accordingly.   
      checkUser();
    }

    let new_dbckey = gql_result.data.createDbcKey;   
    new_dbckey.is_add = true;  // local flag that WE added this object to the list; it is NOT from the initial database list fetch.
    if (allDbcKeys === null)
      setAllDbcKeys([new_dbckey]);
    else
      setAllDbcKeys([new_dbckey, ...allDbcKeys]);  // put new items at head of list. 
    setSortMayMakeSense(true);

    setOwnerName("");
    document.getElementById("owner_name").value = "";

    // no call to setKeyType() since we want user's last selection (K or P) to remain intact for their convienence.

    setKeyNumber("");
    document.getElementById("key_number").value = "";

    setKeyIsMissing(false);
    document.getElementById("key_is_missing").checked = false;
  };


  const [modDbcKey_obj,         setModDbcKeyObj]          = useState(null);
  const [modDbcKeyOwnerName,    setModDbcKeyOwnerName]    = useState(null);
  const [modDbcKeyKeyType,      setModDbcKeyKeyType]      = useState(null);
  const [modDbcKeyKeyNumber,    setModDbcKeyKeyNumber]    = useState(null);
  const [modDbcKeyKeyIsMissing, setModDbcKeyKeyIsMissing] = useState(null);

  function isValidModDbcKeyChange() {
    if (modDbcKey_obj === null)
      return false;

    if (modDbcKeyOwnerName    === null ||
        modDbcKeyKeyType      === null ||
        modDbcKeyKeyNumber    === null ||
        modDbcKeyKeyIsMissing === null)
      return false;

    if (modDbcKeyOwnerName.length === 0 ||
        modDbcKeyKeyType.length   === 0 ||
        modDbcKeyKeyNumber.length === 0)
      return false;

    if (modDbcKeyOwnerName    === modDbcKey_obj.owner_name &&
        modDbcKeyKeyType      === modDbcKey_obj.key_type   &&
        modDbcKeyKeyNumber    === modDbcKey_obj.key_number &&
        modDbcKeyKeyIsMissing === modDbcKey_obj.key_is_missing)
      return false;

    return true;
  }

  const beginModifyDbcKey = async (dbckey) => {
    setDelDbcKeyId(null);
    setModDbcKeyObj(dbckey);
    setModDbcKeyOwnerName(dbckey.owner_name);
    setModDbcKeyKeyType(dbckey.key_type);
    setModDbcKeyKeyNumber(dbckey.key_number);
    setModDbcKeyKeyIsMissing(dbckey.key_is_missing);
  }

  const cancelModifyDbcKey = async (id) => {
    setModDbcKeyObj(null);
  }

  const doModifyDbcKey = async (id) => {
    let newDbcKey = { 
      id, 
      owner_name:     modDbcKeyOwnerName, 
      key_type:       modDbcKeyKeyType,
      key_number:     modDbcKeyKeyNumber, 
      key_is_missing: modDbcKeyKeyIsMissing
    };

    try {
      await API.graphql(graphqlOperation(updateDbcKey, { input: newDbcKey }));
    }
    catch (e) {
      alert("Error doing updateDbcKey(" + 
            "object id " + id + ": " +
            modDbcKeyOwnerName + " owns " + (modDbcKeyKeyIsMissing ? "missing " : "") + modDbcKeyKeyType + ":" + modDbcKeyKeyNumber + "): " + 
            JSON.stringify(e));

      return;
    }
    finally {
      // Access/ID tokens may have expired and been refreshed... recheck user's groups and modify visible tabs accordingly.   
      checkUser();
    }

    newDbcKey.is_mod = true;  // local flag that WE modified this object in the list; it is NOT from the initial database list fetch.
    const modifiedDbcKeys = [...allDbcKeys].map((dbckey) => dbckey.id === newDbcKey.id ? newDbcKey : dbckey);
    setAllDbcKeys(modifiedDbcKeys);
    setSortMayMakeSense(true);

    setModDbcKeyObj(null);
    setModDbcKeyOwnerName(null);
    setModDbcKeyKeyType(null);
    setModDbcKeyKeyNumber(null);
    setModDbcKeyKeyIsMissing(null);
  };


  const [delDbcKeyId, setDelDbcKeyId] = useState(null);

  const beginDeleteDbcKey = async (id) => {
    setModDbcKeyObj(null);
    setDelDbcKeyId(id);
  }

  const cancelDeleteDbcKey = async (id) => {
    setDelDbcKeyId(null);
  }

  const doDeleteDbcKey = async (id) => {
    //const dbckey = allDbcKeys.find(({ id: _id }) => _id === id);
    const del_dbckey = { id };  // , owner_name: dbckey.owner_name // must be only the @key fields, else you'll get an error.

    try {
      await API.graphql(graphqlOperation(deleteDbcKey, { input: del_dbckey }));
    }
    catch (e) {
      const dk_obj = allDbcKeys.find(({ id: _id }) => _id === id);
      const dk_msg = dk_obj ? (
        "object id " + id + ": " +
        dk_obj.owner_name + " owns " + (dk_obj.key_is_missing ? "missing " : "") + dk_obj.key_type + ":" + dk_obj.key_number
      ) : (
        "locally unknown object id " + id
      );

      alert("Error doing deleteDbcKey(" + dk_msg + "): " + JSON.stringify(e));

      return;
    }
    finally {
      // Access/ID tokens may have expired and been refreshed... recheck user's groups and modify visible tabs accordingly.   
      checkUser();
    }

    const modifiedDbcKeys = [...allDbcKeys].filter(({ id: _id }) => _id !== id);
    setAllDbcKeys(modifiedDbcKeys);

    setDelDbcKeyId(null);
  };


  function nItems(n, whenSingle, whenPlural) {
    return n + " " + ((n === 1) ? whenSingle : whenPlural);
  }


  return (
    <div className="App">


      <div className="heading">
        <h1>Daytona Beach Club</h1>
        <div className="sign-out">
          <AmplifySignOut/>
          <label>{userName ? userName : ""}</label>
          <label>{userGrps ? ("   (" + userGrps.join(", ") + ")") : ""}</label>
        </div>
      </div>

      <Toaster/>

      <Tabs>

        <div label="Wait" show={userTabs === 0}>

          <h2>{userName ? "All functionality is blocked... userid needs to be approved." : "Unable to establish userid approval status."}</h2>

          <p/>

          <button
              onClick={() => {
                checkUser();
                if (userTabs === 0)
                  toast((userName ? "No change is status" : "Problem persists") + "; please try again later.");
              }} 
          >{userName ? "Check Approval Status" : "Retry"}</button>

        </div>

        <div label="Parking Pass" show={(userTabs & 1) !== 0}>
          <h2>Search for Pass Owner</h2>

          <form className="get-dbckey-form" onSubmit={submitGetDbckeyByKeyNumberP}>
            <input style={{marginLeft: "10px", width: "50px"}}
              id="search_keynum_p"
              placeholder="PASS#"
              onChange={changeSearchKeynumP}
              onFocus={clearSearchResultP}
              //onBlur={clearSearchResultP}  // lose focus
            />
            <button 
              type="submit" 
              style={{marginLeft: "10px", backgroundColor: search_keynum_p.length === 0 ? "whitesmoke" : "lightgreen"}}
              disabled={search_keynum_p.length === 0}
            >SEARCH</button>
            <div className="heading">
              {search_result_p.map((s, i) => (
                <h2 key={i} // special "key" prop used by react to handle re-rendering list changes.  static list so ordinal i is enough.
                >{s}</h2>))}
            </div>
          </form>
        </div>

        <div label="Pool Key" show={(userTabs & 2) !== 0}>
          <h2>Search for Key Owner</h2>

          <form className="get-dbckey-form" onSubmit={submitGetDbckeyByKeyNumberK}>
            <input style={{marginLeft: "10px", width: "50px"}}
              id="search_keynum_k"
              placeholder="KEY#"
              onChange={changeSearchKeynumK}
              onFocus={clearSearchResultK}
              //onBlur={clearSearchResultK}  // lose focus
            />
            <button 
              type="submit" 
              style={{marginLeft: "10px", backgroundColor: search_keynum_k.length === 0 ? "whitesmoke" : "lightgreen"}}
              disabled={search_keynum_k.length === 0}
            >SEARCH</button>

            <div className="heading">
              {search_result_k.map((s, i) => (
                <h2 key={i} // special "key" prop used by react to handle re-rendering list changes.  static list so ordinal i is enough.
                >{s}</h2>))}
            </div>
          </form>
        </div>
      

        <div label="Manage List" show={(userTabs & 4) !== 0}>
            <h2>Keys and Passes for All Units</h2>

            <form className="add-dbckey-form" onSubmit={submitAddDbcKey}>
              <input 
                style={{marginLeft: "5px", width: "50px"}}
                id="owner_name"
                placeholder="UNIT#"
                onChange={(e) => setOwnerName(e.target.value.replace(/ +/, "").replace(/^0+/, ""))}
              />
              <select 
                style={{marginLeft: "10px"}}
                id="key_type"
                onChange={(e) => setKeyType(e.target.value.replace(/ +/, ""))}
                value={key_type}
              >
                <option value="K">Key</option>
                <option value="P">Pass</option>
              </select>
              <input 
                style={{marginLeft: "10px", width: "50px"}}
                id="key_number"
                placeholder="ITEM#"
                onChange={(e) => setKeyNumber(e.target.value.replace(/ +/, "").replace(/^0+/, ""))}
              />
              <label
                style={{marginLeft: "6px"}}
                ><input
                  type="checkbox"
                  id="key_is_missing"
                  onChange={(e) => setKeyIsMissing(e.target.checked)}
                  checked={key_is_missing}
                />Lost?</label
              >
              <button
                type="submit" 
                style={{marginLeft: "10px", backgroundColor: owner_name.length === 0 || key_number.length === 0 ? "whitesmoke" : "lightgreen"}}
                disabled={owner_name.length === 0 || key_number.length === 0}
              >ADD</button>
            </form>

            <p/>

            <button
              onClick={() => {
                sortAllDbcKeys(allDbcKeys)
                toast("List has been sorted.");
              }}
              disabled={!sortMayMakeSense}
            >Sort Changes within List</button>

            <button
              style={{marginLeft: "10px"}}
              onClick={() => {
                loadAllDbcKeys();
                toast("List has been reloaded from server.");
              }} 
            >Reload List from Server</button>

            <p/>

            {allDbcKeys === null ? (
              <p>Loading Unit/Item list...</p>
            ) : allDbcKeys.length === 0 ? (
              <p>No Unit/Item info is available.</p>
            ) : (
              <div className="dbckeys">

                <label> 
                {
                  nItems(allDbcKeys.length, "item", "items") + 
                  " in list; " + 
                  nItems((allDbcKeys.filter(({ key_type: _kt }) => _kt === 'K').length), "Key", "Keys") + 
                  " and " +
                  nItems((allDbcKeys.filter(({ key_type: _kt }) => _kt === 'P').length), "Pass", "Passes") + 
                  "."
                }
                </label>
                
                <p/>

                <table style={{border: "1px solid black", borderCollapse: "collapse", padding: "10px" , marginLeft: "auto", marginRight: "auto"}}>
                  <thead>
                    <tr>
                      <th style={{border: "1px solid black", padding: "10px"}}>Unit#</th>
                      <th style={{border: "1px solid black", padding: "10px"}}>K or P</th>
                      <th style={{border: "1px solid black", padding: "10px"}}>Item#</th>
                      <th style={{border: "1px solid black", padding: "10px"}}>Lost?</th>
                      <th style={{border: "1px solid black", padding: "10px"}}>Operation</th>
                      </tr>
                  </thead>
                  <tbody>
                    {allDbcKeys.map((dbckey) => (
                      // special "key" prop throughout this is used by react to handle re-rendering list changes.   
                      <tr key={"row_" + dbckey.id} 
                          style={'is_add' in dbckey ? ({backgroundColor: "lightgreen"}) : 
                                ('is_mod' in dbckey ? ({backgroundColor: "PowderBlue"}) : 
                                ({}))}> 
                        <td key={"col_1_row_" + dbckey.id}>
                          {modDbcKey_obj !== null && dbckey.id === modDbcKey_obj.id ? (
                            <input 
                              type="text" 
                              onChange={(e) => setModDbcKeyOwnerName(e.target.value.replace(/ +/, "").replace(/^0+/, ""))} 
                              value={modDbcKeyOwnerName}
                              style={{color: "blue", width: "50px"}}
                            />
                          ) : (
                            <label style={dbckey.id === delDbcKeyId ? ({color: "red", textDecoration: "line-through"}) : ({})}
                            >{dbckey.owner_name}</label>
                          )}
                        </td>
                        <td key={"col_5_row_" + dbckey.id}>
                          {modDbcKey_obj !== null && dbckey.id === modDbcKey_obj.id ? (
                            <select 
                              //id={dbckey.id}  //?????? what is this for?
                              onChange={(e) => setModDbcKeyKeyType(e.target.value)}
                              value={modDbcKeyKeyType}
                              key={"element_within_col_5_row_" + dbckey.id}
                              style={{color: "blue", backgroundColor: "white"}}
                              >
                              <option value="K">Key</option>
                              <option value="P">Pass</option>
                            </select>
                          ) : (
                            <label style={dbckey.id === delDbcKeyId ? ({color: "red", textDecoration: "line-through"}) : ({})}
                            >{dbckey.key_type === "K" ? ("Key") : dbckey.key_type === "P" ? "Pass" : ("?(" + dbckey.key_type + ")")}</label>
                          )}
                        </td>  
                        <td key={"col_2_row_" + dbckey.id}>
                          {modDbcKey_obj !== null && dbckey.id === modDbcKey_obj.id ? (
                            <input 
                              type="text" 
                              onChange={(e) => setModDbcKeyKeyNumber(e.target.value.replace(/ +/, "").replace(/^0+/, ""))} 
                              value={modDbcKeyKeyNumber}
                              style={{color: "blue", width: "50px"}}
                            />
                          ) : (
                            <label style={dbckey.id === delDbcKeyId ? ({color: "red", textDecoration: "line-through"}) : ({})}
                            >{dbckey.key_number}</label>
                          )}
                        </td>
                        <td key={"col_3_row_" + dbckey.id}> 
                          {modDbcKey_obj !== null && dbckey.id === modDbcKey_obj.id ? (
                            <input
                              type="checkbox"
                              onChange={(e) => setModDbcKeyKeyIsMissing(e.target.checked)}
                              //id={dbckey.id}     //?????? what is this for?
                              //value={dbckey.id}  //?????? what is this for?
                              checked={modDbcKeyKeyIsMissing}
                              key={"element_within_col_3_row_" + dbckey.id}
                              // default is gray border when unchecked, solid blue with white checkmark when checked.
                              //style={{color: "blue", borderColor: "blue"}}  // has no effect.
                            />
                          ) : (
                            <label style={dbckey.id === delDbcKeyId ? ({color: "red", textDecoration: "line-through"}) : ({})}
                            >{dbckey.key_is_missing ? "LOST" : ""}</label>
                          )}
                        </td>
                        <td key={"col_4_row_" + dbckey.id}> 
                          {modDbcKey_obj !== null && dbckey.id === modDbcKey_obj.id ? (
                            <div>
                              <label style={{color: "blue"}}>Mod?</label>
                              <button
                                onClick={() => doModifyDbcKey(dbckey.id)} 
                                key={"element_1b_within_col_4_row_" + dbckey.id} 
                                style={{marginLeft: "5px",
                                        backgroundColor: isValidModDbcKeyChange() ? "aqua" : "whitesmoke"}}
                                disabled={!isValidModDbcKeyChange()}
                              >Y</button>
                              <button
                                onClick={() => cancelModifyDbcKey(dbckey.id)} 
                                key={"element_1c_within_col_4_row_" + dbckey.id} 
                                style={{marginLeft: "5px"}}
                              >N</button>
                            </div>
                          ) : (dbckey.id === delDbcKeyId ? (
                            <div>
                              <label style={{color: "red"}}>Del?</label>
                              <button 
                                onClick={() => doDeleteDbcKey(dbckey.id)} 
                                key={"element_2b_within_col_4_row_" + dbckey.id} 
                                style={{marginLeft: "5px"}}
                              >Y</button>
                              <button 
                                onClick={() => cancelDeleteDbcKey(dbckey.id)} 
                                key={"element_2c_within_col_4_row_" + dbckey.id} 
                                style={{marginLeft: "5px"}}
                                >N</button>
                            </div>
                          ) : (
                            <div>
                              <button 
                                onClick={() => beginModifyDbcKey(dbckey)} 
                                key={"element_1_within_col_4_row_" + dbckey.id} 
                                style={{marginLeft: "2px"}}
                              >MOD</button>
                              <button 
                                onClick={() => beginDeleteDbcKey(dbckey.id)} 
                                key={"element_2a_within_col_4_row_" + dbckey.id} 
                                style={{marginLeft: "8px"}}
                              >DEL</button>
                            </div>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                
              </div>
            )}
        </div>

      </Tabs>


    </div>
  );
}

export default withAuthenticator(App, {
  usernameAlias: "email",  // on signup screen do not want separate username and email fields; email should act as username.  on signin screen it should have email not username field.   usernameAttributes does not work but usernameAlias seems to.
  signUpConfig: {
    //hiddenDefaults: ["phone_number"]  // on signup screen do not want phone field at all.  but this doesn't seem to work hiding it.
    slot: "sign-up",
    formFields: ["email", "password"]
  }
});