// https://www.digitalocean.com/community/tutorials/react-tabs-component
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';


class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      activeTab:   "", // set in render so as to account for hidden tabs based on thier props.show value, which has not yet been correctly set when we are constructed.
      setViaClick: false
    };
  }


  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
    this.setState({ setViaClick: true });
  }


  render() {

    // seems to work, but do get the following in f12 debugger on very 1st render call:
    //    Warning: Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state.
    //
    //console.log("children:  " + this.props.children.map((c) => {return c.props.label + " (" + c.props.show + ")"}).join(", "));
    const filtered_children = this.props.children.filter((c) => {
      //console.log("render:  show=", c.props.show, "label=", c.props.label); 
      return c.props.show
    });
    //console.log("filtered_children:  " + filtered_children.map((c) => {return c.props.label + " (" + c.props.show + ")"}).join(", "));
    if (!this.state.setViaClick &&  // we only want to INITIALIZE activeTab.  once user clicks to change tab, never override that choice.
        this.state.activeTab !== filtered_children[0].props.label) // don't want to recursively loop render() calls by us setting state.
      this.setState({ activeTab: filtered_children[0].props.label });
    //console.log("activeTab", this.state.activeTab);

    const {
      onClickTabItem,
      props: {
        children,
      },
      state: {
        activeTab,
      }
    } = this;

    return (
      <div className="tabs">
        <ol className="tab-list">
          {children.filter((c) => {
            //console.log("tab-List:  show=", c.props.show, "label=", c.props.label); 
            return c.props.show;
          }).map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.filter((c) => {
            //console.log("tab-content:  show=", c.props.show, "label=", c.props.label); 
            return c.props.show
          }).map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;