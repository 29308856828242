/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDbcKey = /* GraphQL */ `
  query GetDbcKey($id: ID!) {
    getDbcKey(id: $id) {
      id
      owner_name
      key_type
      key_number
      key_is_missing
      createdAt
      updatedAt
    }
  }
`;
export const listDbcKeys = /* GraphQL */ `
  query ListDbcKeys(
    $filter: ModelDbcKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDbcKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner_name
        key_type
        key_number
        key_is_missing
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dbckeyByKeyNumber = /* GraphQL */ `
  query DbckeyByKeyNumber(
    $key_number: String
    $sortDirection: ModelSortDirection
    $filter: ModelDbcKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dbckeyByKeyNumber(
      key_number: $key_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner_name
        key_type
        key_number
        key_is_missing
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
