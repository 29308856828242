/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDbcKey = /* GraphQL */ `
  mutation CreateDbcKey(
    $input: CreateDbcKeyInput!
    $condition: ModelDbcKeyConditionInput
  ) {
    createDbcKey(input: $input, condition: $condition) {
      id
      owner_name
      key_type
      key_number
      key_is_missing
      createdAt
      updatedAt
    }
  }
`;
export const updateDbcKey = /* GraphQL */ `
  mutation UpdateDbcKey(
    $input: UpdateDbcKeyInput!
    $condition: ModelDbcKeyConditionInput
  ) {
    updateDbcKey(input: $input, condition: $condition) {
      id
      owner_name
      key_type
      key_number
      key_is_missing
      createdAt
      updatedAt
    }
  }
`;
export const deleteDbcKey = /* GraphQL */ `
  mutation DeleteDbcKey(
    $input: DeleteDbcKeyInput!
    $condition: ModelDbcKeyConditionInput
  ) {
    deleteDbcKey(input: $input, condition: $condition) {
      id
      owner_name
      key_type
      key_number
      key_is_missing
      createdAt
      updatedAt
    }
  }
`;
