/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://k7spr7lb2zgxbkzfe3nzclxy7y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:0622de76-e23c-42d3-9f11-573b0b69f0ad",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_t2GFIJdhG",
    "aws_user_pools_web_client_id": "7b3bp0ikqh70jjl44g4vide6av",
    "oauth": {}
};


export default awsmobile;
